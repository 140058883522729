import React from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { getImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { BgImage } from "gbimage-bridge";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import LogoCloud from "../components/Repeating/LogoCloud";
import Testimonials from "../components/Repeating/Testimonials";
import CallToAction from "../components/Repeating/CTA";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
    li {
      ${tw`text-sm lg:text-base tracking-wider lg:tracking-normal`}
    }
  }
  h3 {
    ${tw`font-body normal-case tracking-wider	text-base font-bold`}
  }
  p {
    ${tw`text-sm lg:text-base`}
  }
`;

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const heroImages = [
    getImage(data.heroDesktop.childImageSharp.gatsbyImageData),
    {
      ...getImage(data.heroMobile.childImageSharp.gatsbyImageData),
      media: `(max-width: 767px)`,
    },
  ];

  return (
    <Layout headerStyle="overlap">
      <SearchEngineOptimization
        title="Commercial Cleaning Services | HCS"
        description="We bring five-star commercial cleaning services to any setting nationwide. Count on us to care for your commercial property properly. Get in touch now!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="pt-48 md:pt-64 lg:pt-80 xl:pt-[490px] pb-14 md:pb-52 lg:pb-[415px] relative mb-8 lg:mb-0">
        <BgImage
          className="inset-0 w-full h-full bg-center"
          image={heroImages}
          style={{ position: "absolute" }}
        />
        <div className="container relative">
          <h1 className="mb-4 md:mb-4 md:max-w-[560px]">
            Commercial Cleaning Services
          </h1>
          <p className="md:text-xl mb-0 md:max-w-sm">
            Expert cleaning. Highly-trained staff.
          </p>
        </div>
      </section>

      <LogoCloud className="relative lg:-top-60 mb-16 md:-mb-6" />

      <section className="relative mb-20 md:mb-32">
        <div className="absolute top-0 w-full"></div>
        <div className="relative container">
          <ScrollWrapper className="grid lg:grid-cols-12 gap-y-16 lg:gap-x-24">
            <ScrollNavigation className="lg:col-start-1 lg:col-span-4">
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-1"}
                  title="About"
                  stripHash
                  className="no-underline"
                />
              </li>
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-2"}
                  title="Services"
                  stripHash
                  className="no-underline"
                />
              </li>
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-3"}
                  title="What We Do"
                  stripHash
                  className="no-underline"
                />
              </li>
            </ScrollNavigation>

            <ScrollContent className="lg:col-end-13 lg:col-span-8">
              <StyledContent>
                <div id="anchor-1" className="mb-14 lg:mb-16">
                  <h2>For a Clean, Welcoming Environment</h2>
                  <p>
                    Whether it’s an office, a boardroom, or a bathroom, our
                    skilled commercial cleaning team provides first-class
                    cleaning services from top to bottom. We clean office
                    spaces, floors, kitchens, escalators, and more, plus we have
                    specialized services such as pressure washing and
                    construction cleanup. You can count on us to care for your
                    commercial property.
                  </p>
                </div>

                <div id="anchor-2" className="mb-14 lg:mb-16">
                  <h2 className="text-mobile-4xl md:text-4xl">Our Services</h2>
                  <p>
                    Your valuable commercial property is as important to us as
                    it is to you and we’ll help care for your offices, floors,
                    kitchens, escalators, and outdoor spaces.
                  </p>
                  <ul>
                    <li>Commercial Office Cleaning</li>
                    <li>Floor Cleaning & Care</li>
                    <li>Marble and Stone Floors & Countertop Restoration</li>
                    <li>Wood Floors Restoration</li>
                    <li>Carpet Extraction</li>
                    <li>Escalator Cleaning</li>
                    <li>Commercial Kitchen Cleaning</li>
                    <li>Pressure Washing</li>
                    <li>Construction Cleanup</li>
                    <li>LEED Building Services</li>
                  </ul>
                </div>

                <div id="anchor-3">
                  <h2 className="text-mobile-4xl md:text-4xl">
                    More on What We Do
                  </h2>
                  <h3>Commercial Office Cleaning</h3>
                  <p>
                    We bring our cleaning expertise from the hospitality
                    industry to the commercial office marketplace. We’re your
                    one-stop shop for janitorial services. Give your customers,
                    tenants, and employees the best spaces by selecting us as
                    your cleaning partner.
                  </p>

                  <h3>Floor Cleaning & Care</h3>
                  <p>
                    We specialize in carpet cleaning and extraction and hard
                    floor care that includes stripping, waxing, and marble
                    maintenance and restoration. We keep your floors looking new
                    and guarantee you’ll be satisfied with the results we
                    provide.
                  </p>

                  <h3>Escalator Cleaning</h3>
                  <p>
                    We’ll maintain the appearance of this highly trafficked area
                    by cleaning and polishing all stainless steel surfaces and
                    landing plates and removing soil and debris from tracks.
                    We’ll also clean and shine all clear or glass surfaces.
                  </p>

                  <h3>Commercial Kitchen Cleaning</h3>
                  <p>
                    Our professional staff takes the hassle out of kitchen
                    cleanliness. We handle it all, from breaking down kitchen
                    equipment and ovens for detailed cleaning to scrubbing walls
                    and floors. This frees up your culinary team to focus on
                    what they do best.
                  </p>

                  <h3>Pressure Washing</h3>
                  <p>
                    Keep the outside of your commercial property pristine
                    looking with our pressure washing services. Our dedicated
                    staff uses state-of-the-art pressure washing equipment to
                    clean everything from sidewalks to loading docks.
                  </p>

                  <h3>Construction Cleanup</h3>
                  <p>
                    Need a team to help clean up after construction? We have the
                    staff to help you clean up following brand new builds,
                    renovations, restorations, additions, upgrades, and
                    remodels. And we always go above and beyond to make sure you
                    are completely satisfied.
                  </p>

                  <h3>LEED Building Services</h3>
                  <p>
                    At Hotel Cleaning Services, we fully understand the
                    requirements and costs of achieving the prestigious LEED
                    Building Certification. And we take as much pride in your
                    facility as you do.
                  </p>
                  <p>
                    Hotel Cleaning Services has been certified with honors
                    through the distinguished ISSA (International Sanitary
                    Supply Association) CIMS Green Building program. Only a very
                    small percentage of all commercial cleaning companies have
                    successfully completed this rigorous program.
                  </p>
                  <p>
                    As your LEED partner, our commitment to you is to custom
                    tailor a cleaning program that will meet and exceed all the
                    requirements outlined in your LEED certification. At the
                    same time, we’ll remain a cost-effective and value-driven
                    resource for you.{" "}
                  </p>
                </div>
              </StyledContent>
            </ScrollContent>
          </ScrollWrapper>
        </div>
      </section>

      <Testimonials />
      <CallToAction
        heading={[
          "Count on the ",
          <span className="text-primary-500">Experts</span>,
        ]}
        subtext="We have high-skilled commercial cleaning staff who stand ready to exceed your expectations. Contact us today!"
      />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Commercial Spaces Cleaning.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Commercial Spaces Cleaning.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(
      relativePath: {
        eq: "services/2.4 Commercial Cleaning/1.0 Commercial Hero Desktop.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    heroMobile: file(
      relativePath: {
        eq: "services/2.4 Commercial Cleaning/1.0 Commercial Hero mobile.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
export default Page;
